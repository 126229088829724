var markers = [];
function highlightMarker(i) {
  if (markers[i].getAnimation() !== null) {
    markers[i].setAnimation(null);
  } else {
    markers[i].setAnimation(google.maps.Animation.BOUNCE);
  }
}
function stopHighlightMarker(i) {
  if (markers[i].getAnimation() !== null) {
    markers[i].setAnimation(null);
  }
}
function initContactUsMap2(){
    var lCount;
    var sum1 = 0;
    var sum2 = 0;
    for (lCount = 0; lCount < locations.length; lCount++) {
        sum1 = sum1 + locations[lCount][1];
        sum2 = sum2 + locations[lCount][2];
    }
    var center1 = sum1 / lCount;
    var center2 = sum2 / lCount;

    //var myLatlng = new google.maps.LatLng(52.3898907, 4.9034652999999935);
    var myLatlng = new google.maps.LatLng(center1, center2);
    var mapOptions = {
            zoom: 14,
            center: myLatlng,
            styles: [
                {"featureType":"water","elementType":"geometry","stylers":[{"color":"#E6EEF8"},{"lightness":17}]},
                //{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#d5d5d5"},{"lightness":20}]},
                //{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},
                //{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},
                //{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},
                //{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},
                //{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},
                //{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},
                //{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},
                //{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},
                //{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},
                //{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},
                //{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},
                //{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}
            ],
            scrollwheel: false,
        }
    var map = new google.maps.Map(document.getElementById("contactUsMap2"), mapOptions);

    var infowindow = new google.maps.InfoWindow();

    var marker, i;
    for (i = 0; i < locations.length; i++) {
        marker = new google.maps.Marker({
            position: new google.maps.LatLng(locations[i][1], locations[i][2]),
            map: map
        });
        markers.push(marker);
        google.maps.event.addListener(marker, 'click', (function(marker, i) {
            return function() {
                infowindow.setContent(locations[i][0]);
                infowindow.open(map, marker);
            }
        })(marker, i));
    }
    $(".notmap").hover(function() {
        highlightMarker($(this).data('value'));
        }, function() {
        stopHighlightMarker($(this).data('value'));
    });
}
var byPassSplashShownCookie = false;
var splash_shown = Cookies.get('splash_shown');
if(byPassSplashShownCookie){
    splash_shown = false;
    Cookies.set('splash_shown', false);
}

function add_js_input(form_id){
    $('<input>').attr({
                type: 'hidden',
                id: 'captcha',
                name: 'captcha',
                value: 'honey'}).appendTo(form_id);
}

$(document).ready(function(){
    //materialDocumentReady();
    //if($("#contactUsMap").length) {
    //    materialKitDemo.initContactUsMap();
    //}
    $('body').fitVids(); 
    

    if($('.submenu_js_active').length){
        if($('.submenu_js_active .list-group-item').hasClass('active')){}else{
            $('.submenu_js_active .list-group-item').first().addClass('active');
        }
    }
    $('.parallax-window').parallax();
    $('.removeSplash').on('click',function(e){
        e.preventDefault();
        $('.splash').removeClass('active');
        $('.splash').fadeOut(120);
        $('body').removeClass('splash_active');
        Cookies.set('splash_shown', true);
    });
    if(!splash_shown){
        $('.splash').fadeIn(120);
        $('.splash').addClass('active');
        $('body').addClass('splash_active');
        progress(15, 15, $('#progressBar'));
        //initMasonry();
        //setTimeout(initMasonry, 1200);
    }
    $('.homeFeed').imagesLoaded( function() {
        initMasonry();
    });
    
	  
});
$(window).on("load", function (e) {
	
	$('.js_filter_by_tag').on('change',function(e){
        var str = "";
        $( "select option:selected" ).each(function() {
            str += $( this ).val();
        });
        if (str !='all'){
            redirVar = str;
        }else{
            redirVar = curr_module_base;
        }
        window.location.replace(redirVar);
    });
	var cw = $('.imageholder').width();
	$('.imageholder').css({'height':cw+'px'});
	
		var cw = $('.imageholder-thumb').width();
	$('.imageholder-thumb').css({'height':cw+'px'});

    $("table").addClass('table table-bordered table-hover');
    if(window.open_deeplink){
        open_deeplink();
    }
    if(window.validate_contact_form){
		validate_contact_form();
		add_js_input('#contact_form');
	}
	if(window.validate_modal_form){
		validate_modal_form();
		add_js_input('#modal_form');
	}
    if(window.validate_aankoop_form){
		validate_aankoop_form();
		add_js_input('#aankoop_form');
	}
    //demo.initContactUsMap2();
    if($('#contactUsMap2').length){
        initContactUsMap2();
    }
    cookieAkkoord = Cookies.get('cookieAkkoord');
    if(cookieAkkoord == undefined){
        $('.cookie').fadeIn(300);
    }
    $('.close_cookie').on('click',function(e){
        Cookies.set('cookieAkkoord', 'shown');
        $('.cookie').fadeOut(300);
    });
})
$(window).resize(function() {
	var cw = $('.imageholder').width();
$('.imageholder').css({'height':cw+'px'});

		var cw = $('.imageholder-thumb').width();
	$('.imageholder-thumb').css({'height':cw+'px'});

});

$(document).on('click', '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});
function initMasonry(){
    $('.grid').masonry({
      itemSelector: '.grid-item'
      //columnWidth: 200
    });
}
function progress(timeleft, timetotal, $element) {
    var progressBarWidth = timeleft * $element.width() / timetotal;
    $element
        .find('div')
        //.animate({ width: progressBarWidth }, 500)
        .animate({ width: progressBarWidth }, timeleft == timetotal ? 0 : 1000, "linear")
        //.html(timeleft + " seconds to go")
        ;
    if(timeleft > 0) {
        setTimeout(function() {
            progress(timeleft - 1, timetotal, $element);
        }, 1);
    }else{
        $('.splash').removeClass('active');
        $('.splash').fadeOut(120);
        $('body').removeClass('splash_active');
        Cookies.set('splash_shown', true);
    }
};